export const ROUTES = {
  APP: '/app',
  BILLING: '/app/billing',
  BILLING_CALLBACK: '/app/billing/callback',
  DASHBOARD: '/app/dashboard',
  DESIGN: '/app/design',
  FRIEND_DESIGN: '/app/design/friends',
  FUNNELS: '/app/funnels',
  FUNNELS_CREATE: '/app/funnels/create',
  FUNNELS_EDIT: '/app/funnels/edit',
  ONBOARDING_FINISH: '/app/onboarding/finish',
  ONBOARDING_WELCOME: '/app/onboarding/welcome',
  STABLE_DIFFUSION_WEBHOOK: '/stable-diffusion/webhook',
  SETTINGS: '/app/settings',
  LOGIN: '/auth/login',
  SHARED: '/app/proxy/shared',
}
